@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Zodiak";
  src: url(./fonts/Zodiak-Regular.woff2) format("woff2"),
    url(./fonts/Zodiak-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Thin.ttf) format("ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Italic.ttf) format("ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("ttf");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

ul {
  @apply list-disc mt-2 mb-8;
}

ul li {
  @apply ml-8 mb-1 md:ml-12;
}

.body-content a {
  @apply border-b border-outer-space border-opacity-50;
}

a {
  @apply transition-all duration-300;
}

a:hover {
  @apply opacity-50;
}

h2 {
  @apply font-sans text-2xl mb-4 md:mb-2 -mt-1 font-light;
}

.nav-link-active {
  @apply border-b-2 border-outer-space border-opacity-100 font-bold;
}
